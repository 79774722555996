import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/0-layout"
import Query from "../components/query"
import SEO from "../components/seo"
import "../scss/faq.scss"

/**
 * @property {object} allFaqJson
 * @property {object} ques
 * @property {object} ans
 * @property {object} dealer
 * @property {object} common
 */

const FaqPage = () => {
  const data = Query()

  const faq = useStaticQuery(graphql`
    query FaqQuery {
      allFaqJson {
        edges {
          node {
            warehouse {
              ques
              ans
            }
            dealer {
              ques
              ans
            }
            common {
              ques
              ans
            }
          }
        }
      }
    }
  `)

  const getDlItems = (v) => {
    let items = []

    v.forEach((i) => {
      items.push(
        <>
          <li>{i.ques}</li>
          <li style={{ whiteSpace: "pre-line" }}>{i.ans}</li>
        </>
      )
    })

    return items
  }

  return (
    <Layout sitePath={"faq"} pageTitle={data.site.siteMetadata.faq}>
      <SEO title={data.site.siteMetadata.faq} />

      <h1 className={"title-blue"}>倉庫業者</h1>
      <ul class={`items`}>
        {getDlItems(faq.allFaqJson.edges[0].node.warehouse)}
      </ul>

      <h1 className={"title-blue"}>タイヤ取扱業者</h1>
      <ul class={`items`}>{getDlItems(faq.allFaqJson.edges[1].node.dealer)}</ul>

      <h1 className={"title-blue"}>共通</h1>
      <ul class={`items`}>{getDlItems(faq.allFaqJson.edges[2].node.common)}</ul>
    </Layout>
  )
}

export default FaqPage
